import { useContext, useEffect, useState } from 'react';
import App from './app';
import LoggingContext, { LoggingContextProps } from './context/loggingContext';
import { querySearchInternal, resolveHashParams, fixHash } from './utils/queryUtils';
import { setLang } from './i18n';
import { decodeBase64 } from './utils/objectUtils';
import SessionWrapper from '../sessions/sessionWrapper';

const AppWrapper = () => {
    const { logger } = useContext<LoggingContextProps>(LoggingContext);
    const sessionWrapper = new SessionWrapper();
    const [params, setParams] = useState(null);

    useEffect(() => {
        //  #/lang=cs&caseType=CT1344&startedFrom=PRODUCT_DASHBOARD&subCaseType=CT17&productId=03D49DBE643AB099BDD319207414B194F833D3D3&hidden_cluid=1998-10-27-03.25.54.858474#access_token=MOCK-1581501441124&startedFrom=PRODUCT_DASHBOARD

        const localParams = querySearchInternal(window.location.search);
        const hashParams = resolveHashParams(window.location.hash);
        //merged params from search query + client part of url
        const mergedParams = { ...localParams, ...hashParams };

        //sometimes url is in the shape of https://servicing.csast.csas.cz/#/caseType=CTxy&module=BOP&.... this /#/caseT... is wrong. Right shape is /#/?caseType=....
        window.location.hash = fixHash(window.location.hash);

        const module = resolveParam(mergedParams, 'module');
        const lang = resolveParam(mergedParams, 'lang');
        //AC-42276 - SES
        const salesChannel = resolveParam(mergedParams, 'saleschannel');
        const authType = resolveParam(mergedParams, 'authtype');

        console.log('Resolved module: ' + module);

        if (module) {
            sessionWrapper.setModule(module);
        }

        //AC-42276 - SES
        if (salesChannel) {
            sessionWrapper.setSalesChannel(salesChannel);
        }

        if (authType) {
            sessionWrapper.setAuthType(authType);
        }

        setLang(lang);

        logger.debug(mergedParams);

        setParams(mergedParams);
    }, []);

    /**
     * Universal method to retrieve a key from param object.
     */
    const resolveParam = (parameters, key) => {
        //try for web req param
        const param = parameters[key];

        if (param) {
            return param;
        }

        //try for go state
        const { state } = parameters;
        if (!state) {
            return null;
        }

        const decodedParams = decodeBase64(state);
        if (!decodedParams) {
            return null;
        }

        const foundKey = Object.keys(decodedParams).filter(
            (tmpKey) => tmpKey.toLowerCase() === key.toLowerCase(),
        );

        if (foundKey && foundKey.length === 1) {
            console.log("foundKey for '" + key + "' is '" + foundKey + "'");
            return decodedParams[foundKey[0]];
        } else {
            console.error("key '" + key + "' was not found");
            return null;
        }
    };

    return params && <App mergedParams={params} />;
};

export default AppWrapper;
