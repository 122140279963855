export interface NgModule {
    name: string;
    url: string;
    cancelCaseOnTimeout: boolean;
    strategy: boolean;
    createCasePathOverride?: string;
    mainUrlOverride?: string;
    translations?: boolean;
    traslationUrlOverride?: string;
}

export const NGRUNTIME_MODULE = {
    name: 'ngruntime',
    url: import.meta.env.VITE_APP_SERVER_RESOURCE_URL,
    cancelCaseOnTimeout: true,
    strategy: false,
    translations: true,
    traslationUrlOverride: import.meta.env.VITE_APP_SERVER_RESOURCE_URL + '/product/translation',
};
export const SMART_MODULE = {
    name: 'smart',
    url: import.meta.env.VITE_APP_SERVER_RESOURCE_URL,
    cancelCaseOnTimeout: true,
    strategy: false,
};
export const NQ_MODULE = {
    name: 'nq',
    url: import.meta.env.VITE_APP_SERVER_RESOURCE_URL,
    cancelCaseOnTimeout: true,
    strategy: false,
};
export const UNO2_MODULE = {
    name: 'uno2',
    url: import.meta.env.VITE_APP_SERVER_RESOURCE_URL,
    cancelCaseOnTimeout: true,
    strategy: false,
};
export const UNO2_ESMII_MODULE = {
    name: 'uno2_esm2',
    url: import.meta.env.VITE_APP_SERVER_RESOURCE_URL,
    cancelCaseOnTimeout: true,
    strategy: false,
};
export const UNO2_PP_MODULE = {
    name: 'uno2_pp',
    url: import.meta.env.VITE_APP_SERVER_RESOURCE_URL,
    cancelCaseOnTimeout: true,
    strategy: false,
};
export const UNO2_RPA_MODULE = {
    name: 'uno2_rpa',
    url: import.meta.env.VITE_APP_SERVER_RESOURCE_URL,
    cancelCaseOnTimeout: true,
    strategy: false,
};
export const ESTORAGE_MODULE = {
    name: 'estorage',
    url: import.meta.env.VITE_APP_ESTORAGE_RESOURCE_URL,
    cancelCaseOnTimeout: true,
    strategy: false,
};

export const ADCA_MODULE = {
    name: 'adca',
    url: import.meta.env.VITE_APP_ADCA_RESOURCE_URL,
    cancelCaseOnTimeout: true,
    strategy: true,
};
export const BOP_MODULE = {
    name: 'bop',
    url: import.meta.env.VITE_APP_BOP_RESOURCE_URL,
    cancelCaseOnTimeout: false,
    strategy: true,
};
export const BUFEDO_MODULE = {
    name: 'bufedo',
    url: import.meta.env.VITE_APP_BUFEDO_RESOURCE_URL,
    cancelCaseOnTimeout: false,
    strategy: true,
};
export const CABU_MODULE = {
    name: 'cabu',
    url: import.meta.env.VITE_APP_CABU_RESOURCE_URL,
    cancelCaseOnTimeout: false,
    strategy: true,
};
export const CAR_MODULE = {
    name: 'car',
    url: import.meta.env.VITE_APP_CAR_RESOURCE_URL,
    cancelCaseOnTimeout: false,
    strategy: true,
    mainUrlOverride: import.meta.env.VITE_APP_RKT_RESOURCE_URL,
    translations: true,
    traslationUrlOverride: NGRUNTIME_MODULE.url + '/product/translation/rkt',
};
export const CCT_MODULE = {
    name: 'cct',
    url: import.meta.env.VITE_APP_CCT_RESOURCE_URL,
    cancelCaseOnTimeout: false,
    strategy: true,
};
export const CGA_MODULE = {
    name: 'cga',
    url: import.meta.env.VITE_APP_CGA_RESOURCE_URL,
    cancelCaseOnTimeout: false,
    strategy: true,
};
export const COL_MODULE = {
    name: 'col',
    url: import.meta.env.VITE_APP_COL_RESOURCE_URL,
    cancelCaseOnTimeout: true,
    strategy: true,
};
export const COSI_MODULE = {
    name: 'cosi',
    url: import.meta.env.VITE_APP_COSI_RESOURCE_URL,
    cancelCaseOnTimeout: false,
    strategy: true,
};
export const FITSERV_MODULE = {
    name: 'fitserv',
    url: import.meta.env.VITE_APP_FITSERV_RESOURCE_URL,
    cancelCaseOnTimeout: true,
    strategy: true,
};
export const G4BMIGR_MODULE = {
    name: 'g4bmigr',
    url: import.meta.env.VITE_APP_G4BMIGR_RESOURCE_URL,
    cancelCaseOnTimeout: false,
    strategy: true,
    translations: true,
};
export const G4B_MODULE = {
    name: 'corporate',
    url: import.meta.env.VITE_APP_G4B_RESOURCE_URL,
    cancelCaseOnTimeout: true,
    strategy: true,
};
export const GACC_MODULE = {
    name: 'gacc',
    url: import.meta.env.VITE_APP_GACC_RESOURCE_URL,
    cancelCaseOnTimeout: true,
    strategy: true,
};
export const GASER_MODULE = {
    name: 'gaser',
    url: import.meta.env.VITE_APP_GASER_RESOURCE_URL,
    cancelCaseOnTimeout: true,
    strategy: true,
};
export const GBUM_MODULE = {
    name: 'gbum',
    url: import.meta.env.VITE_APP_GBUM_RESOURCE_URL,
    cancelCaseOnTimeout: false,
    strategy: true,
};
export const GCEDR_MODULE = {
    name: 'gcedr',
    url: import.meta.env.VITE_APP_GCEDR_RESOURCE_URL,
    cancelCaseOnTimeout: true,
    strategy: true,
};
export const GCORPKYC_MODULE = {
    name: 'gcorpkyc',
    url: import.meta.env.VITE_APP_GCORPKYC_RESOURCE_URL,
    cancelCaseOnTimeout: true,
    strategy: true,
};
export const GMSE_MODULE = {
    name: 'gmse',
    url: import.meta.env.VITE_APP_GMSE_RESOURCE_URL,
    cancelCaseOnTimeout: false,
    strategy: true,
};
export const GSBOX_MODULE = {
    name: 'gsbox',
    url: import.meta.env.VITE_APP_GSBOX_RESOURCE_URL,
    cancelCaseOnTimeout: false,
    strategy: true,
};
export const IQ_MODULE = {
    name: 'iq',
    url: import.meta.env.VITE_APP_IQ_RESOURCE_URL,
    cancelCaseOnTimeout: true,
    strategy: true,
    translations: true,
};
export const INDI_MODULE = {
    name: 'indi',
    url: import.meta.env.VITE_APP_INDI_RESOURCE_URL,
    cancelCaseOnTimeout: true,
    strategy: true,
};
export const KYC_MODULE = {
    name: 'kyc',
    url: import.meta.env.VITE_APP_KYC_RESOURCE_URL,
    cancelCaseOnTimeout: true,
    strategy: true,
};
export const LIAB_MODULE = {
    name: 'liab',
    url: import.meta.env.VITE_APP_SERVER_RESOURCE_URL,
    cancelCaseOnTimeout: true,
    strategy: true,
};
export const GMNT_MODULE = {
    name: 'mnt',
    url: import.meta.env.VITE_APP_GMNT_RESOURCE_URL,
    cancelCaseOnTimeout: true,
    strategy: true,
    createCasePathOverride: '/mnt/case',
};
export const PAC_MODULE = {
    name: 'pac',
    url: import.meta.env.VITE_APP_PAC_RESOURCE_URL,
    cancelCaseOnTimeout: true,
    strategy: true,
    translations: true,
};
export const PENS_MODULE = {
    name: 'pens',
    url: import.meta.env.VITE_APP_PENSIONS_RESOURCE_URL,
    cancelCaseOnTimeout: true,
    strategy: true,
    translations: true,
    traslationUrlOverride: import.meta.env.VITE_APP_PENSIONS_RESOURCE_URL + '/product/translation',
};
export const POPLESC_MODULE = {
    name: 'poplesc',
    url: import.meta.env.VITE_APP_POPLESC_RESOURCE_URL,
    cancelCaseOnTimeout: false,
    strategy: true,
};
export const PRONOP_MODULE = {
    name: 'pronop',
    url: import.meta.env.VITE_APP_PRONOP_RESOURCE_URL,
    cancelCaseOnTimeout: false,
    strategy: true,
};
export const PSAV_MODULE = {
    name: 'psav',
    url: import.meta.env.VITE_APP_PSAV_RESOURCE_URL,
    cancelCaseOnTimeout: false,
    strategy: true,
};
export const RKT_MODULE = {
    name: 'rkt',
    url: import.meta.env.VITE_APP_RKT_RESOURCE_URL,
    cancelCaseOnTimeout: false,
    strategy: false,
};
export const SAFEBOX_MODULE = {
    name: 'safebox',
    url: import.meta.env.VITE_APP_SAFEBOX_RESOURCE_URL,
    cancelCaseOnTimeout: true,
    strategy: true,
};
export const SBS_MODULE = {
    name: 'sbs',
    url: import.meta.env.VITE_APP_SBS_RESOURCE_URL,
    cancelCaseOnTimeout: true,
    strategy: true,
};
export const TDEP_MODULE = {
    name: 'tdep',
    url: import.meta.env.VITE_APP_TDEP_RESOURCE_URL,
    cancelCaseOnTimeout: false,
    strategy: true,
};
export const TRAIN_MODULE = {
    name: 'train',
    url: import.meta.env.VITE_APP_TRAIN_RESOURCE_URL,
    cancelCaseOnTimeout: false,
    strategy: true,
};
export const UFO_MODULE = {
    name: 'ufo',
    url: import.meta.env.VITE_APP_UFO_RESOURCE_URL,
    cancelCaseOnTimeout: true,
    strategy: true,
};
export const VCHOD_MODULE = {
    name: 'vchod',
    url: import.meta.env.VITE_APP_VCHOD_RESOURCE_URL,
    cancelCaseOnTimeout: false,
    strategy: true,
};
export const ZAFU_MODULE = {
    name: 'zafu',
    url: import.meta.env.VITE_APP_ZAFU_RESOURCE_URL,
    cancelCaseOnTimeout: false,
    strategy: true,
};
export const RCT_MODULE = {
    name: 'rct',
    url: import.meta.env.VITE_APP_RCT_RESOURCE_URL,
    cancelCaseOnTimeout: false,
    strategy: true,
};
export const GL_MODULE = {
    name: 'gl',
    url: import.meta.env.VITE_APP_GL_RESOURCE_URL,
    cancelCaseOnTimeout: false,
    strategy: true,
};

export const MODULES: NgModule[] = [
    NGRUNTIME_MODULE,
    GMNT_MODULE,
    SMART_MODULE,
    NQ_MODULE,
    PAC_MODULE,
    GACC_MODULE,
    FITSERV_MODULE,
    UNO2_MODULE,
    UNO2_ESMII_MODULE,
    UNO2_PP_MODULE,
    UNO2_RPA_MODULE,
    CAR_MODULE,
    RKT_MODULE,
    PENS_MODULE,
    ESTORAGE_MODULE,
    COL_MODULE,
    UFO_MODULE,
    BOP_MODULE,
    G4B_MODULE,
    IQ_MODULE,
    GCEDR_MODULE,
    KYC_MODULE,
    VCHOD_MODULE,
    POPLESC_MODULE,
    LIAB_MODULE,
    GSBOX_MODULE,
    ADCA_MODULE,
    ZAFU_MODULE,
    GCORPKYC_MODULE,
    CABU_MODULE,
    SAFEBOX_MODULE,
    SBS_MODULE,
    PRONOP_MODULE,
    G4BMIGR_MODULE,
    CGA_MODULE,
    TDEP_MODULE,
    GBUM_MODULE,
    GASER_MODULE,
    PSAV_MODULE,
    GMSE_MODULE,
    CCT_MODULE,
    TRAIN_MODULE,
    COSI_MODULE,
    BUFEDO_MODULE,
    INDI_MODULE,
    RCT_MODULE,
    GL_MODULE,
];

export const cancelCaseOnTimeout = (moduleName) => {
    const module = MODULES.find((m) => m.name === moduleName);
    return module ? module.cancelCaseOnTimeout : true;
};

export const LEGACY_AUTH_MODULES = [G4B_MODULE, G4BMIGR_MODULE, GL_MODULE];
