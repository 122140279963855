import React, { useState } from 'react';
import { FesyncPollingField } from '@csas-smart/gti-ui-comps';
import RestCaller from '../core/restCaller';
import Resource from '../core/serverresource';
import { resolveHashIdFromState } from '../core/utils/taskUtils';

const FesyncPollingFieldContainer = (props) => {
    const hashId = resolveHashIdFromState();

    const [error, setError] = useState(false);
    const [appLink, setAppLink] = useState('');

    const pollForSyncCase = () => {
        RestCaller.httpPureGet(Resource.pollSyncNextTask(hashId))
            .then(Resource.checkStatus)
            .then(Resource.getJsonBody)
            .then((pollResponse) => {
                console.log(pollResponse);
                if (pollResponse?.applicationUrl) {
                    setAppLink(pollResponse.applicationUrl);
                }
            })
            .catch((err) => {
                console.error(
                    'Error during polling for the next task: ' + hashId + '. Message: ' + err,
                );
                setError(true);
            });
    };

    const isError = () => {
        return error;
    };

    const onError = () => {
        console.error('Cancelling polling for the next task: ' + hashId);
    };

    const onPollingTimout = () => {
        console.error('Timeout during polling for the next task: ' + hashId);
    };

    const isPollingSuccessful = () => {
        return !!appLink;
    };

    const onPollingSuccesful = () => {
        window.open(appLink, '_self');
    };

    return (
        <FesyncPollingField
            {...props}
            pollCondition={true}
            onPoll={() => pollForSyncCase()}
            successPollCondition={isPollingSuccessful()}
            onSuccessPoll={() => onPollingSuccesful()}
            failurePollCondition={isError()}
            onFailurePoll={() => onError()}
            onTimeout={() => onPollingTimout()}
        />
    );
};

export default FesyncPollingFieldContainer;
